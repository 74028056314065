import * as React from "react"
import {graphql} from "gatsby";
import {I18nContext, useTranslation} from 'gatsby-plugin-react-i18next';

import FullWidthContainer from "../components/grid/FullWidthContainer";
import StageTeaser from "../components/molecules/StageTeaser/StageTeaser";
import Text from "../components/atoms/Text/Text";
import List from "../components/molecules/List/List";
import ColoredBox from "../components/molecules/ColoredTextBox/ColoredBox";
import Button from "../components/atoms/Button/Button";
import PageReferencesTitle from "../components/atoms/PageReferencesTitle/PageReferencesTitle";
import PageReferencesText from "../components/atoms/PageReferencesText/PageReferencesText";
import Image from "../components/atoms/Image/Image";
import GridContainer from "../components/grid/GridContainer";
import HalfWidthCell from "../components/grid/HalfWidthCell";
import ThreeCellsWideCell from "../components/grid/ThreeCellsWideCell";
import TranslatedLink from "../components/util/TranslatedLink";
import Seo from "../components/global/Seo/Seo";

type propType = {
	data: any
	classes: any,
	context: any
}
const DiagnosisPage = ({data}: propType) => {
	const {t} = useTranslation();

	const context = React.useContext(I18nContext);
	const currentLanguage = context.i18n.language;
	return (
		<>
			<Seo title={t('diagnosis:seo.title')} description={t('diagnosis:seo.description')} />
			<FullWidthContainer>
				<StageTeaser imageAlt={t('diagnosis:imageAlt')} image={data.stageTeaser} />

				<h1>{t('diagnosis:mainHeadline')}</h1>
				<p>
					<Text text={t('diagnosis:sectionIntroduction.copy1')}/>
				</p>
				<p>
					<Text text={t('diagnosis:sectionIntroduction.copy2')}/>
				</p>
				<p>
					<Text text={t('diagnosis:sectionIntroduction.copy3')}/>
				</p>
			</FullWidthContainer>
			<FullWidthContainer>
				<h2>
					<Text text={t('diagnosis:sectionOtherInvestigations.headline')}/>
				</h2>
				<List list={t('diagnosis:sectionOtherInvestigations.list', {returnObjects: true})} />
			</FullWidthContainer>
			<ColoredBox>
				<FullWidthContainer>
					<h2>{t('diagnosis:sectionDiagnosisAlgorithm.headline')}</h2>
					<h3>
						<Text text={t('diagnosis:sectionDiagnosisAlgorithm.copy1')}/>
					</h3>
				</FullWidthContainer>
				<GridContainer>
					<HalfWidthCell expandForMobile={true}>
						<Image className="margin-top-1" imagePath={"/assets/images/diagnosis/" + currentLanguage + "/algorithm_complete.svg"} imageAlt={t("diagnosis:sectionDiagnosisAlgorithm.imageAltText")} caption={t('diagnosis:sectionDiagnosisAlgorithm.imageFooter')} />
					</HalfWidthCell>
				</GridContainer>
			</ColoredBox>
			<FullWidthContainer>
				<h2>{t('diagnosis:sectionEnzymeActivity.headline')}</h2>
				<Text text={t('diagnosis:sectionEnzymeActivity.copy1')}/>
			</FullWidthContainer>
			<FullWidthContainer>
				<h2 className="margin-top-section">{t('diagnosis:sectionLaborDiagnostics.headline')}</h2>
				<Text text={t('diagnosis:sectionLaborDiagnostics.copy1')}/>
			</FullWidthContainer>
			<GridContainer>
				<ThreeCellsWideCell expandForMobile={true}>
					<Image className="margin-top-medium margin-bottom-large" imagePath={"/assets/images/diagnosis/" + currentLanguage + "/laboratory-diagnostics.svg"} imageAlt={t("diagnosis:sectionLaborDiagnostics.imageAltText")} caption={t('diagnosis:sectionLaborDiagnostics.graphFooter')} />
				</ThreeCellsWideCell>
			</GridContainer>
			<FullWidthContainer>
				<TranslatedLink title={t('orderButton.title')} to="/order">
					<Button uppercase={true} inverted={true}>
						{t('diagnosis:sectionReferences.buttonLabel')}
					</Button>
				</TranslatedLink>
				<PageReferencesTitle>
					<Text text="diagnosis:sectionReferences.headline" />
				</PageReferencesTitle>
				<PageReferencesText>
					<Text text="diagnosis:sectionReferences.copy" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="diagnosis:sectionReferences.explanation" />
				</PageReferencesText>
				<PageReferencesText>
					<Text text="approvalCode" />
				</PageReferencesText>
			</FullWidthContainer>
		</>
	)
}

export default DiagnosisPage

export const query = graphql`
	query($language: String!) {
		locales: allLocale(filter: {language: {eq: $language}, ns: {in: ["translation", "diagnosis"]}}) {
			edges {
				node {
					ns
					data
					language
				}
			}
		},
		stageTeaser: file(
			relativePath: { eq: "diagnosis/StageTeaserDesktop.jpg"}
		) {
			id
			relativePath
			childImageSharp {
				gatsbyImageData(
					placeholder: BLURRED,
					formats: [AUTO, WEBP, AVIF],
					quality: 75,
					sizes: "(min-width: 1300px) 1292px, (min-width: 640px) 100vw,  200vw"

				)
			}
		}
	}
`;
