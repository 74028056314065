import React from "react";

import * as styles from "./GridContainer.module.scss";
import classNames from "classnames";

type propsType = {
  children: React.ReactNode,
  className?: string,
}

const GridContainer = (props: propsType) => (
  <div className={classNames(styles.root, props.className)}>
    {props.children}
  </div>
);

export default GridContainer;