import React from "react";
import * as styles from "./List.module.scss";
import Text from "../../atoms/Text/Text";

const List = ({list}: {list: string[] | string[][]}) => {
    const listItems = list.map((item) => {
        if (Object.prototype.toString.call(item) === '[object Array]') {
            // key props are necessary for re-rerender detection in react
            // (otherwise: warning)
            return <List key={Math.random().toString(36).substr(2, 9)} list={item as string[]} />
        } else {
            return <li key={Math.random().toString(36).substr(2, 9)}><Text text={item as string}/></li>
        }
    });

    return (
        <ul className={styles.root}>
            {listItems}
        </ul>
    )
}

export default List;