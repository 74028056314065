import React from "react";

import * as styles from "./Image.module.scss";
import Text from "../Text/Text";
import {withPrefix} from "gatsby";

type propsTypes = {
    imagePath: string
    imageAlt: string
    caption?: string
    className?: string
}

const Image = (props: propsTypes) => {

    const caption = () => {
        if (props.caption) {
           return (<div className={styles.caption}><Text text={props.caption} /></div>);
        } else {
            return '';
        }
    }

    return (
        <div className={props.className}>
            <img className={styles.image} src={withPrefix(props.imagePath)} alt={props.imageAlt} />
            {caption()}
        </div>
    );
}

export default Image;