import React from "react";

import * as styles from "./HalfWidthCell.module.scss";
import classNames from "classnames";

type propsType = {
  children: React.ReactNode
  expandForMobile?: boolean
  expandforSmallOnly?: boolean
}


const HalfWidthCell = (props: propsType) => {

  let rootClasses = classNames(
    styles.root,
    {[styles.expandForMobile]: props.expandForMobile},
    {[styles.expandForSmallOnly]: props.expandforSmallOnly}
  );

  return (<div className={rootClasses}>
    {props.children}
  </div>);
};

export default HalfWidthCell;