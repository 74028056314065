import React from "react";
import {GatsbyImage, getImage} from "gatsby-plugin-image";
import * as styles from './StageTeaser.module.scss'
import Text from "../../atoms/Text/Text";
type propsType = {
    image: any
    imageAlt:any
}
const StageTeaser = (props: propsType) => {
    return (
        <div className={styles.root}>
            <span className={styles.hero}>
                <Text text="slogan" />
            </span>
            <GatsbyImage
                image={getImage(props.image)!}
                loading="eager"
                alt={props.imageAlt}
                className={styles.imageWrapper}
            />
        </div>
    );
}

export default StageTeaser;